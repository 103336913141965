import { tns } from 'tiny-slider/src/tiny-slider';

$(() => {
    "use strict";

    var clients_slider_mobile = tns({
        container: '#clients-slider-mobile',
        items: 3,
        nav:true,
        controls:false,
        speed: 400,
        gutter: 30,
        navPosition: "bottom",
    });

    let feedback_slider = tns({
        container: '#feedback-slider',
        items: 1,
        controls: true,
        speed: 400,
        gutter: 10,
        navPosition: "bottom",
        controlsText: ["<i class='fal fa-angle-left'></i>", "<i class='fal fa-angle-right'></i>"],
        autoplay: false,
        autoplayHoverPause: true,
        autoplayButtonOutput: false
    });

    let lastWorks_slider = tns({
        container: '#lastWorks-slider',
        items: 1,
        controls: false,
        navContainer: "#lastWorks-thumbnails",
        navAsThumbnails: true,
        speed: 400,
        gutter: 10,
        autoplay: false,
        autoplayHoverPause: true,
        autoplayButtonOutput: false
    });
})
